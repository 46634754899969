import { defineStore } from 'pinia';
import { LocalStorage } from 'quasar';
import { api } from 'src/boot/axios';
import {
  ContentPlan,
  SubscriptionPlan,
  User,
  UserProfile,
} from 'components/models';
import { useContentPlanStore } from './content-plan';
import { useChatMessagesStore } from './chat-messages';
import { useQuestionsStore } from './questions';
import { useContentPlansStore } from './choose-module';
import { useMarketingKitStore } from './marketing-kit';
import { useTopicsStore } from './post-topics';

const USER_ID_UNAUTHENTICATED = -1;
const USER_ID_NOT_FOUND = 0;

const EMPTY_CONTENT_PLAN: ContentPlan = {
  id: 0,
  name: '',
  description: '',
  is_adaptive: false,
  is_for_projects_home_page: false,
  content_plan_group_id: 0,
  content_plan_group: {
    id: 0,
    description: '',
  },
  video_1_id: '',
  video_2_id: '',
  new_content_plan_text: '',
};

const EMPTY_PROFILE: UserProfile = {
  id: 0,
  profile_label: '',
  content_plan_id: 0,
  personal_texts_id: 0,
  image_requests_id: 0,
  content_plan: EMPTY_CONTENT_PLAN,
};

const EMPTY_SUBSCRIPTION_PLAN: SubscriptionPlan = {
  id: 0,
  name: '',
  description: '',
  limit_description: '',
  has_limit: false,
  initial_limit: 0,
  trial_wo_card: false,
  max_num_profiles: 0,
  is_trial: false,
};

const NO_USER: User = {
  id: USER_ID_UNAUTHENTICATED,
  reporting_user_id: 0,
  first_name: '',
  last_name: '',
  email: '',
  disabled: false,
  can_change_module: false,
  is_admin: false,
  needs_to_be_told_about_projects: false,
  credits_remaining: 0,
  show_credits_counter: false,
  subscription_url: '',
  subscription_plan: EMPTY_SUBSCRIPTION_PLAN,
  active_profile: EMPTY_PROFILE,
  preferences: {
    hide_gen_confirm: false,
  },
  active_org: {
    name: '',
    subscription_plan: EMPTY_SUBSCRIPTION_PLAN,
  },
  addons: [],
};

const HIDE_GEN_CONFIRM_STORAGE_KEY = 'cogniscript_hide_gen_confirm';
const LOGIN_NOW_AUTH_FLOW_STORAGE_KEY = 'cogniscript_login_now_initiated';

type ThisStoreState = {
  user: User;
  user_profiles: UserProfile[];
  error: boolean;
  loading: boolean;
  loading_profiles: boolean;
  token: string;
};

async function fetch_user(state: ThisStoreState) {
  state.loading = true;
  state.error = false;
  await api
    .get('/user/')
    .then((res) => {
      state.user = res.data;
      if (!state.user.active_profile) {
        state.user.active_profile = EMPTY_PROFILE;
      }
      if (!state.user.subscription_plan) {
        state.user.subscription_plan = EMPTY_SUBSCRIPTION_PLAN;
      }
      if (!state.user.preferences) {
        state.user.preferences = {
          hide_gen_confirm: false,
        };
      }
      state.user.preferences.hide_gen_confirm = LocalStorage.getItem(
        HIDE_GEN_CONFIRM_STORAGE_KEY
      )
        ? true
        : false;
    })
    .catch((err) => {
      if (401 === err.response?.status) {
        state.user.id = USER_ID_UNAUTHENTICATED;
      } else {
        state.error = true;
      }
    })
    .finally(() => (state.loading = false));
}

function fetch_user_profiles(state: ThisStoreState) {
  state.loading_profiles = true;
  api
    .get('/profiles/')
    .then((res) => {
      state.user_profiles = res.data;
    })
    .catch(() => {
      state.error = true;
    })
    .finally(() => {
      state.loading_profiles = false;
    });
}

export const useUserStore = defineStore('user', {
  state: () =>
    ({
      user: NO_USER,
      user_profiles: [],
      error: false,
      loading: false,
      loading_profiles: false,
      token: LocalStorage.getItem('cogniscript_id_token'),
    } as ThisStoreState),

  getters: {
    get_user(state) {
      if (state.user.id == USER_ID_UNAUTHENTICATED && !state.loading) {
        fetch_user(state);
      }
      return state.user;
    },
    get_user_profiles(state) {
      if (!this.is_authorized_now) {
        return [];
      }

      if (
        !state.user_profiles.length &&
        !state.loading_profiles &&
        !state.error
      ) {
        fetch_user_profiles(state);
      }
      return state.user_profiles;
    },
    not_found_in_db(state) {
      return state.user.id == USER_ID_NOT_FOUND;
    },
    async is_authenticated(state) {
      if (state.user.id == USER_ID_UNAUTHENTICATED && !state.loading) {
        await fetch_user(state);
      }
      /**
       * We return true if the user is authenticated or if there is an error.
       * This means we were unable to access the API server. In this case, we do
       * not want for the user to get redirected to the login page, which would
       * happen if we returned false. If the API server is unavailable
       * (state.error == true), there will be other errors and they will be
       * displayed to the user.
       */
      return state.user.id > USER_ID_UNAUTHENTICATED || state.error;
    },
    async is_authorized(state): Promise<boolean> {
      if (state.user.id == USER_ID_UNAUTHENTICATED && !state.loading) {
        await fetch_user(state);
      }
      return this.is_authorized_now;
    },
    is_authorized_now(state) {
      /**
       * Returning true if the user is not disabled in the DB or if there was an
       * error fetching the user info. In the latter case, there will be other
       * errors and they will be displayed to the user.
       */
      return (
        (state.user.id > USER_ID_UNAUTHENTICATED && !state.user.disabled) ||
        state.error
      );
    },
    can_change_module(state) {
      return state.user.can_change_module;
    },
    has_content_plan_for_project_based_home_page(state) {
      return state.user.active_profile.content_plan?.is_for_projects_home_page;
    },
    has_adaptive_content_plan(state) {
      return state.user.active_profile.content_plan?.is_adaptive;
    },
    content_plan_id(state): number {
      if (this.is_authorized_now && this.has_adaptive_content_plan) {
        const content_plan_store = useContentPlanStore();

        if (
          content_plan_store.have_accepted_generated_content_plan &&
          content_plan_store.get_active_content_plan_id
        ) {
          return content_plan_store.get_active_content_plan_id;
        }
      } else if (state.user.active_profile.content_plan_id) {
        return state.user.active_profile.content_plan_id;
      }
      return -1;
    },
    link_to_content_plan(state): string {
      const content_plan_id = this.content_plan_id;

      if (this.has_adaptive_content_plan) {
        return '/weekly-plan';
      } else if (content_plan_id > 0) {
        return '/content-plan/' + state.user.active_profile.content_plan_id;
      }

      return '';
    },
    login_now_srv() {
      return LocalStorage.getItem(LOGIN_NOW_AUTH_FLOW_STORAGE_KEY);
    },
    hasAddOn:
      (state) =>
      (addonId: number): boolean => {
        return state.user.addons.some((addon) => addon.id === addonId);
      },
    getLoraProjectLimit(state): number {
      const loraAddons = state.user.addons?.filter((addon) =>
        this.constants.loraSettings.addonSubscriptionPlanIds.includes(addon.id)
      );
      if (loraAddons?.length > 0) {
        // Higher plan overrides lower ones
        // max_num_profiles is the project limit for LoRA
        return Math.max(
          ...loraAddons.map((addon) => addon.max_num_profiles || 0)
        );
      }
      return 0;
    },
    hasLoraAddon(): boolean {
      return this.getLoraProjectLimit > 0;
    },
  },

  actions: {
    set_token(token: string) {
      this.token = token;

      if (token) {
        LocalStorage.set('cogniscript_id_token', token);
      } else {
        LocalStorage.remove('cogniscript_id_token');
      }
    },
    async reload_user() {
      await fetch_user(this);
    },
    logout() {
      this.set_token('');
      this.user = NO_USER;
      this.user_profiles = [];
    },
    set_and_save_hide_gen_confirm(value: boolean) {
      this.user.preferences.hide_gen_confirm = value;
      LocalStorage.set(HIDE_GEN_CONFIRM_STORAGE_KEY, value);
    },
    set_login_now_srv(value: string) {
      LocalStorage.set(LOGIN_NOW_AUTH_FLOW_STORAGE_KEY, value);
    },
    async add_new_user_profile(profile_label: string, copy_from?: UserProfile) {
      this.loading_profiles = true;
      await api
        .post('/profiles/', {
          profile_label: profile_label,
          copy_from_id: copy_from ? copy_from.id : 0,
        })
        .then((res) => {
          const new_profile: UserProfile = res.data;
          this.user_profiles.push(new_profile);
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_profiles = false;
        });
    },
    async rename_user_profile(profile_id: number, new_profile_label: string) {
      const p_index = this.user_profiles.findIndex(
        (profile) => profile.id == profile_id
      );
      if (p_index < 0) {
        return;
      }
      this.user_profiles[p_index].profile_label = new_profile_label;
      this.loading_profiles = true;
      await api
        .put('/profiles/' + profile_id, new_profile_label)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_profiles = false;
        });
    },
    async choose_user_profile(profile_id: number) {
      this.loading_profiles = true;
      await api
        .patch('/profiles/' + profile_id)
        .then(async () => {
          const chat_messages = useChatMessagesStore();
          chat_messages.reload_messages = true;

          const questions_store = useQuestionsStore();
          questions_store.mk_questions_loaded = false;
          questions_store.post_questions_loaded = false;

          const modules_store = useContentPlansStore();
          modules_store.loaded = false;

          const content_plan_store = useContentPlanStore();
          content_plan_store.loaded = false;
          content_plan_store.accepted = false;
          content_plan_store.content_plans = [];
          content_plan_store.save_active_content_plan_id(0);

          const mk_store = useMarketingKitStore();
          mk_store.loaded = false;

          const topics_store = useTopicsStore();
          topics_store.content_plan_id = 0;

          await this.reload_user();
        })
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_profiles = false;
        });
    },
    async delete_user_profile(profile_id: number) {
      const p_index = this.user_profiles.findIndex(
        (profile) => profile.id == profile_id
      );
      if (p_index < 0) {
        return;
      }
      this.user_profiles.splice(p_index, 1);
      this.loading_profiles = true;
      await api
        .delete('/profiles/' + profile_id)
        .catch(() => {
          this.error = true;
        })
        .finally(() => {
          this.loading_profiles = false;
        });
    },
    update_user() {
      this.user.needs_to_be_told_about_projects = false;
      api.patch('/user/', this.user).catch(() => {
        this.error = true;
      });
    },
  },
});
