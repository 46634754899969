import type { AxiosError } from 'axios';

/**
 * A custom error shape used across the app.
 * This is the "source of truth" for how we represent errors.
 */
export interface AppError {
  isNetworkError: boolean;
  status: number | null;
  message: string;
  data?: unknown;
}

/**
 * Checks if the given object is an AxiosError (runtime guard).
 */
function isAxiosError(error: unknown): error is AxiosError {
  return (
    typeof error === 'object' &&
    error !== null &&
    // at runtime, it will typically have "isAxiosError: true"
    'isAxiosError' in error
  );
}

/**
 * Type guard to check if error.response.data has a message or detail field.
 */
function hasMessageOrDetailField(
  data: unknown
): data is { message?: string; detail?: string } {
  return (
    typeof data === 'object' &&
    data !== null &&
    (('message' in data &&
      typeof (data as Record<string, unknown>).message === 'string') ||
      ('detail' in data &&
        typeof (data as Record<string, unknown>).detail === 'string'))
  );
}

/**
 * A helper that transforms the raw error into an AppError (if possible).
 */
export function buildAppError(error: unknown): AppError {
  // Default or fallback shape
  const base: AppError = {
    isNetworkError: false,
    status: null,
    message:
      'При загрузке данных произошла ошибка. Попробуйте обновить страницу.',
  };

  if (!isAxiosError(error)) {
    // Not even an AxiosError - we can return a fallback
    return {
      ...base,
      message: 'Что-то пошло не так. Код ошибки 986.',
    };
  }

  // It's an AxiosError. Let's see if there's a response
  if (!error.response) {
    // => network error (no response from server)
    return {
      ...base,
      isNetworkError: true,
      message: 'Нет соединения с сервером. Пропал интернет?',
    };
  }

  // => we have a server response with status code
  const status = error.response.status;
  const responseData = error.response.data;

  let serverMessage: string | null = null;

  if (hasMessageOrDetailField(responseData)) {
    if (typeof responseData.message === 'string') {
      serverMessage = responseData.message;
    } else if (typeof responseData.detail === 'string') {
      serverMessage = responseData.detail;
    }
  }

  return {
    ...base,
    isNetworkError: false,
    status,
    message:
      serverMessage || `Что-то сервер нас не понял, вернул код ${status}.`,
    data: responseData,
  };
}
