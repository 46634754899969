import { Notify } from 'quasar';
import { useMarketingKitStore } from 'src/stores/marketing-kit';
import { useUserStore } from 'src/stores/user';
import { RouteRecordRaw } from 'vue-router';
import { Router } from './index';

async function maybe_redirect_to_choose_module() {
  const user = useUserStore();
  const canAccess = await user.is_authenticated;

  /**
   * If the user has not chosen content plan, we are redirecting him to
   * the Choose Module page.
   */
  if (canAccess && !user.user.active_profile.content_plan_id && !user.error) {
    return '/choose-module';
  }

  return true;
}

async function check_if_mk_completed_and_adaptive_plan_still_active() {
  const mk = useMarketingKitStore();
  const user = useUserStore();

  if (!(await mk.get_mk_completed_async) && !mk.error) {
    Notify.create({
      type: 'warning',
      position: 'top',
      timeout: 0,
      message:
        'Для разработки контент-плана необходимо сначала заполнить маркетинг кит.',
      multiLine: true,
      icon: 'las la-edit',
      actions: [
        {
          outline: false,
          label: 'Заполнить маркетинг кит'.toUpperCase(),
          handler: () => {
            Router.push({ path: '/marketing-kit' });
          },
        },
        {
          color: 'white',
          outline: true,
          label: 'Закрыть'.toUpperCase(),
          handler: () => {
            Router.push({ path: '/' });
          },
        },
      ],
    });
    return false;
  }

  /**
   * This protects against the case when the user switches away from from the
   * adaptive module in another open instance of the app and then refreshes the
   * 'weekly plan' page in this instance. In that situation, without this check,
   * the attempt to load the weekly plan page would result in exception.
   */
  if (!user.has_adaptive_content_plan) {
    return '/';
  }

  return true;
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => {
          const user = useUserStore();

          if (user.has_content_plan_for_project_based_home_page) {
            return import('pages/ProjectsBasedHomePage.vue');
          } else if (user.has_adaptive_content_plan) {
            return import('pages/HomePageVer2.vue');
          } else {
            return import('pages/IndexPage.vue');
          }
        },
      },
    ],
    beforeEnter: maybe_redirect_to_choose_module,
  },
  {
    path: '/archive',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ArchivePage.vue'),
      },
    ],
  },

  {
    path: '/weekly-plan',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/WeeklyPlan.vue'),
      },
    ],
    beforeEnter: check_if_mk_completed_and_adaptive_plan_still_active,
  },

  {
    path: '/weekly-plan/questions',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/WeeklyPlanQuestions.vue'),
      },
    ],
    beforeEnter: check_if_mk_completed_and_adaptive_plan_still_active,
  },

  {
    path: '/choose-module',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ChooseModule.vue'),
      },
    ],
  },

  {
    path: '/content-plan/:content_plan_id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ContentPlan.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
        }),
      },
    ],
  },

  {
    path: '/personal-content-plan/:content_plan_id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ContentPlanVer3.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
        }),
      },
    ],
  },

  {
    path: '/personal-texts',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/UserPersonalTexts.vue'),
      },
    ],
  },

  {
    path: '/images',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'arbitrary-image-gen',
        component: () => import('pages/ArbitraryImages.vue'),
      },
    ],
  },

  {
    path: '/avatars',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'lora-projects',
        component: () => import('pages/LoraProjectsPage.vue'),
      },
    ],
  },

  {
    path: '/avatars/:lora_project_id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'lora-training',
        component: () => import('pages/AvatarTrainingPage.vue'),
        props: (route) => ({
          lora_project_id: Number(route.params.lora_project_id),
        }),
      },
    ],
  },

  {
    path: '/images/:content_plan_id?/:post_id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ImageRequestPage.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  {
    path: '/post/:content_plan_id?/:post_id',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/PostPage.vue'),
        props: (route) => ({
          content_plan_id: Number(route.params.content_plan_id),
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  {
    path: '/post/:content_plan_id?/:post_id/images',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ImagesPage.vue'),
        props: (route) => ({
          post_id: Number(route.params.post_id),
        }),
      },
    ],
  },

  {
    path: '/marketing-kit',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/MarketingKit.vue'),
      },
    ],
    beforeEnter: maybe_redirect_to_choose_module,
  },

  {
    path: '/chat/:chat_id?',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ChatPage.vue'),
        props: (route) => ({
          chat_id: Number(route.params.chat_id),
        }),
      },
    ],
  },

  {
    path: '/oauth-redirect',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/AuthCallback.vue') }],
  },

  {
    path: '/login',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: 'now/:srv',
        component: () => import('pages/Login.vue'),
        props: true,
      },
      {
        path: '',
        component: () => import('pages/Login.vue'),
        props: false,
      },
    ],
  },

  {
    path: '/logout',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/Logout.vue') }],
  },

  {
    path: '/profile',
    component: () => import('layouts/MainLayout.vue'),
    children: [{ path: '', component: () => import('pages/ProfilePage.vue') }],
  },

  {
    path: '/profile/manage',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ManageProfiles.vue'),
        props: (route) => ({
          add_new: Number(route.query.add_new),
        }),
      },
    ],
  },

  {
    path: '/admin-posts/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/AdminPostDetails.vue'),
        props: true,
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      { path: '', component: () => import('pages/ErrorNotFound.vue') },
    ],
  },
];

export default routes;
